/* RecoverPage.css */
.recover-container {
    background-color: #BBECEC;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .recover-title {
    color: #333;
    margin-bottom: 20px;
  }
  
  .recover-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
  }
  
  .recover-input {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
  }
  
  .recover-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .recover-button:hover {
    background-color: #0056b3;
  }
  
  .recover-message {
    color: #d9534f;
    margin: 20px 0;
  }
  