/* Homepage.css */
.homepage-container {
  background-color: #BBECEC;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homepage-header {
  color: 000000;
  margin-bottom: 20px;
  text-align: center;
  font-size: 50px;
}
.header-with-logo {
  display: flex;
  align-items: center; /* Centra verticalmente el texto y el logo */
}

.avion-logo {
  width: 70px; /* Ajusta el tamaño del logo según sea necesario */
  height: auto; /* Mantiene la proporción del logo */
  margin-left: 10px;
}

.coming-soon {
  font-size: 24px;
  margin: 20px 0;
  font-style: italic;
}

.store-logos {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.store-logos img {
  height: 120px; /* Ajusta esto según el tamaño deseado */
  margin: 50px;
}

.homepage-nav {
  margin-top: 20px;
}

.homepage-nav ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.homepage-nav ul li {
  margin: 10px 0;
}

.homepage-nav ul li a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

.homepage-nav ul li a:hover {
  text-decoration: underline;
}

.homepage-link {
  color: #007bff; /* Ejemplo de color */
  font-weight: bold;
  text-decoration: none;
  font-size: 24px;
}

.homepage-link:hover {
  text-decoration: underline;
}


@media (max-width: 600px) {
  .homepage-container {
    padding: 100px;
  }

  .homepage-header {
    font-size: 32px; /* Ajusta el tamaño del texto para dispositivos pequeños */
  }

  .coming-soon {
    font-size: 20px;
  }

  .store-logos {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .store-logos img {
    height: 100px; /* Ajusta esto según el tamaño deseado */
    margin: 30px;
  }

  
}
